<template>
  <div>
    <Hero title="Venue" subtitle="Sewio Summit 2019"></Hero>
    <div class="section">
      <div class="container venue">
        <div class="columns">
          <div class="column content" v-html="page.content"></div>
          <div class="column is-4">
            <img
              src="../../assets/venue_detail.jpg"
              alt="Dominant Winery House, Rakvice"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hero from "../common/Hero";
export default {
  name: "Venue",
  components: { Hero },
  data() {
    return {
      loadingComponent: null,
    };
  },
  computed: {
    loaded() {
      return !!this.$root.$data.venue;
    },
    page() {
      return this.loaded ? this.$root.$data.venue : false;
    },
  },
  watch: {
    loaded(newLoaded) {
      if (newLoaded === true && this.loadingComponent !== null) {
        this.loadingComponent.close();
      }
    },
  },
  mounted() {
    if (this.loaded === false) {
      this.loadingComponent = this.$loading.open({ container: this.$el });
    }
  },
};
</script>
