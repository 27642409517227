<template>
  <div>
    <Hero
      title="Smart Material Handling Powered by IIoT"
      subtitle="Clarion Congress Hotel České Budějovice<br>September 25-26, 2019 (Wed - Thu)"
      content="Sewio Summit 2019 is the second outing for the only annual international conference that truly gives substance to those otherwise empty buzzwords of Industry 4.0 and Digital Twin by revealing <b>real-world ROI-driven digitization case studies</b> from within the industry sector. We invite you to join us once more at #SewioSummit where innovation truly accelerates!"
    ></Hero>
    <div class="section homepage--line-up">
      <div class="container">
        <line-up></line-up>
      </div>
    </div>
    <div class="section homepage--value-propositions">
      <div class="container">
        <div class="columns">
          <div class="column">
            <br />
            <p class="title is-2">Need to convince your boss?</p>
            <p class="subtitle is-4">
              Here are some talking points to make your case.
            </p>
            <br />
          </div>
        </div>
        <value-propositions></value-propositions>
      </div>
    </div>
    <div class="venue-image-aspect-ratio">
      <img
        src="../../assets/venue.jpg"
        alt="Venue - Rakvice"
        class="venue-image"
      />
    </div>
  </div>
</template>

<script>
import ValuePropositions from "../organisms/ValuePropositions";
import Hero from "../common/Hero";
import LineUp from "../molecules/LineUp";

export default {
  name: "Homepage",
  components: { LineUp, Hero, ValuePropositions },
};
</script>

<style lang="scss">
@import "../../scss/variables";

.venue-image-aspect-ratio {
  display: block;
  width: 100%;
  padding-bottom: 35%;
  position: relative;
}
.venue-image {
  position: absolute;

  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 35%;
}

.homepage {
  &--value-propositions {
    background-color: $light-background;
  }
}
</style>
